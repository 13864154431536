import { addCollection } from "@iconify/vue"
let _initialized = false
export function init() {
  if (_initialized)
    return
  const collections = JSON.parse("[{\"prefix\":\"mdi\",\"icons\":{\"checkbox-marked\":{\"width\":24,\"height\":24,\"body\":\"<path fill=\\\"currentColor\\\" d=\\\"m10 17l-5-5l1.41-1.42L10 14.17l7.59-7.59L19 8m0-5H5c-1.11 0-2 .89-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2\\\"/>\"},\"checkbox-blank-outline\":{\"width\":24,\"height\":24,\"body\":\"<path fill=\\\"currentColor\\\" d=\\\"M19 3H5c-1.11 0-2 .89-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2m0 2v14H5V5z\\\"/>\"},\"plus\":{\"width\":24,\"height\":24,\"body\":\"<path fill=\\\"currentColor\\\" d=\\\"M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6z\\\"/>\"}}}]")
  for (const collection of collections) {
    addCollection(collection)
  }
  _initialized = true
}