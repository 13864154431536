export default defineAppConfig({
  ui: {
    primary: 'blue',
    gray: 'neutral',
    icons: {
      menu: 'i-mdi-menu',
    },
    tooltip: {
      default: {
        openDelay: 500,
        closeDelay: 0,
      },
    },
    badge: {
      default: {
        variant: 'subtle',
      },
    },
    button: {
      default: {
        loadingIcon: 'i-mdi-loading',
      },
    },
    input: {
      default: {
        loadingIcon: 'i-mdi-loading',
      },
    },
    select: {
      default: {
        loadingIcon: 'i-mdi-loading',
        trailingIcon: 'i-mdi-chevron-down',
      },
    },
    selectMenu: {
      default: {
        selectedIcon: 'i-mdi-check',
      },
    },
    notification: {
      default: {
        closeButton: {
          icon: 'i-mdi-close',
        },
      },
    },
    notifications: {
      position: 'bottom-0 right-1/2 translate-x-1/2', // bottom-center
    },
    commandPalette: {
      default: {
        icon: 'i-mdi-magnify',
        loadingIcon: 'i-mdi-loading',
        selectedIcon: 'i-mdi-check',
        emptyState: {
          icon: 'i-mdi-magnify',
        },
      },
    },
    table: {
      default: {
        sortAscIcon: 'i-mdi-arrow-up',
        sortDescIcon: 'i-mdi-arrow-down',
        sortButton: {
          icon: 'i-mdi-menu-swap',
        },
        loadingState: {
          icon: 'i-mdi-loading',
        },
        emptyState: {
          icon: 'i-mdi-database',
        },
      },
    },
    pagination: {
      default: {
        firstButton: {
          icon: 'i-mdi-chevron-double-left',
        },
        prevButton: {
          icon: 'i-mdi-chevron-left',
        },
        nextButton: {
          icon: 'i-mdi-chevron-right',
        },
        lastButton: {
          icon: 'i-mdi-chevron-double-right',
        },
      },
    },
    accordion: {
      default: {
        openIcon: 'i-mdi-chevron-down',
      },
    },
    breadcrumb: {
      default: {
        divider: 'i-mdi-chevron-right',
      },
    },
  },
})

// see https://nuxt.com/docs/guide/directory-structure/app-config#typing-app-config
declare module '@nuxt/schema' {
  interface CustomAppConfig {
    netzo: {
      locale?: 'es' | 'en' // defaults to 'es'
      favicon: '/favicon.svg' // require SVG favicon for @vite-pwa/assets-generator
      title: string
      description: string
      auth: {
        email?: {
          allowUserRegistration?: boolean
        }
        google?: {
          allowUserRegistration?: boolean
        }
        github?: {
          allowUserRegistration?: boolean
        }
      }
    }
  }

  interface RuntimeConfig {
    session: {
      maxAge: number
    }
    expectedOrigin: string
    expectedRPID: string
    fromEmail: string
    oauth: undefined | {
      google?: OAuthGoogleConfig
      github?: OAuthGitHubConfig
    }
  }

  interface PublicRuntimeConfig {
    baseUrl: string
    locale?: 'es' | 'en'
  }
}

export type ModuleOptions = {
  enabled: boolean
  role: 'admin' | 'edit' | 'view'
}

declare module '#auth-utils' {
  // copied from netzo:users schema in <app>/server/database/schema.ts
  interface User {
    id: string
    provider: string
    name: string
    email: string
    emailVerified: boolean
    role: 'admin' | 'user'
    image: string
    hashedPassword: string
    banned: boolean
    bannedReason: string
    modules: Record<string, ModuleOptions>
    activeAt: string
    createdAt: string
    updatedAt: string
    deletedAt: string
    createdBy: string
    updatedBy: string
    deletedBy: string
  }

  // interface UserSession { }

  // interface SecureSessionData { }
}
