<script setup lang="ts">
const appConfig = useAppConfig()
const route = useRoute()
const colorMode = useColorMode()

const color = computed(() => (colorMode.value === 'dark' ? '#111827' : 'white'))

useHead({
  meta: [
    { charset: 'utf-8' },
    { name: 'viewport', content: 'width=device-width, initial-scale=1' },
    { key: 'theme-color', name: 'theme-color', content: color },
    // route.meta defined with definePageMeta({ title }) at each page
    { property: 'og:title', content: `${appConfig.netzo.title} - ${route.meta.title}` },
  ],
  // see https://vite-pwa-org.netlify.app/assets-generator/#using-preset-minimal-2023
  link: [
    { rel: 'icon', href: '/favicon.ico', sizes: '48x48' },
    { rel: 'icon', href: '/favicon.svg', sizes: 'any', type: 'image/svg+xml' },
    { rel: 'apple-touch-icon', href: '/apple-touch-icon-180x180.png' },
  ],
  htmlAttrs: {
    lang: 'es',
  },
})

useSeoMeta({
  title: appConfig.netzo.title,
  description: appConfig.netzo.description,
  ogTitle: appConfig.netzo.title,
  ogDescription: appConfig.netzo.description,
  ogUrl: 'https://latinta.netzo.dev',
  // NOTE: whatsapp requires image < 300kb (freeconvert.com)
  ogImage: 'https://latinta.netzo.dev/social-card.png',
  twitterTitle: appConfig.netzo.title,
  twitterDescription: appConfig.netzo.description,
  twitterImage: 'https://latinta.netzo.dev/social-card.png',
  twitterCard: 'summary_large_image',
})
</script>

<template>
  <VitePwaManifest />
  <NuxtLoadingIndicator />

  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>

  <UNotifications />
  <UModals />
  <USlideovers />
</template>
